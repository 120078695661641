var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "green-border-box container-small white-bg" },
    [
      _c("video", {
        attrs: {
          src: _vm.demoVideo,
          poster: "/img/video_thumb.png",
          controls: "",
          autoplay: ""
        }
      }),
      _vm.showButton
        ? _c(
            "div",
            { staticClass: "dummy-height" },
            [
              _c(
                "router-link",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isVideoWatched,
                      expression: "isVideoWatched"
                    }
                  ],
                  attrs: { to: "/brand/onboarding/subscription" }
                },
                [
                  _c("button", { staticClass: "btn-lg-green" }, [
                    _c("span", { staticClass: "hideOnMobile" }, [
                      _vm._v("CONTINUE TO")
                    ]),
                    _vm._v("BUY PICKL PACKS\n      ")
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bottom-img text-center" }, [
      _c("img", {
        staticClass: "img-responsive center-block",
        attrs: { src: "/img/hello.png" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }