


















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class BrandVideo extends Vue {
  @Prop({ default: false }) showButton!: boolean;
  public isVideoWatched: boolean = false;
  public demoVideo: string =
    process.env.VUE_APP_ASSETS_URL + '/assets/videos/intro_video.mp4'

  mounted () {
    if (this.showButton) {
      setTimeout(() => {
        this.isVideoWatched = true
      }, 15000)
    }
  }
}
